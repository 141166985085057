import {useEffect, useState} from 'react';
import {StorageHelper} from '../common/helpers/storage-helper';
import {NavigationPanelState} from '../common/types';

export function useNavigationPanelState(): [
  NavigationPanelState,
  (state: Partial<NavigationPanelState>) => void,
] {
  const [currentState, setCurrentState] = useState<NavigationPanelState>({
    collapsed: false, // The navigation panel will start open by default.
    collapsedSections: [],
  });

  useEffect(() => {
    setCurrentState(StorageHelper.getNavigationPanelState());
  }, []);

  const onChange = (state: Partial<NavigationPanelState>) => {
    setCurrentState(StorageHelper.setNavigationPanelState(state));
  };

  return [currentState, onChange];
}
