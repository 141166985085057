'use client';

import {useValidateLogin} from '@/hooks/use-validate-login';
import {AppConfig} from '@/models/app-config';
import {AppContext} from '@/models/app-context';
import {ReactElement, useState} from 'react';

export default function Authenticated(props: {children: ReactElement}) {
  const [config, setConfig] = useState<AppConfig | null>(null);

  useValidateLogin(setConfig);

  return (
    <AppContext.Provider value={config}>{props.children}</AppContext.Provider>
  );
}
