import {useRouter} from 'next/navigation';
import {Dispatch, SetStateAction, useEffect} from 'react';
import * as userSessionRepo from '@/repositories/user-session-repo';
import {AppConfig} from '@/models/app-config';

export const useValidateLogin = (
  setConfig: Dispatch<SetStateAction<AppConfig | null>>
) => {
  const router = useRouter();

  useEffect(() => {
    if (!userSessionRepo.isLoggedIn()) {
      router.push('/login');
    } else {
      const userSession = userSessionRepo.getSession();
      if (userSession) {
        setConfig({
          userSession: userSession,
        });
      } else {
        throw new Error('No user session found.');
      }
    }
  }, [router, setConfig]);
};
