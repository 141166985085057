import {SessionInfo} from '@/models/session-info';
import * as chatSessionRepo from '@/repositories/chat-session-repo';
import {useCollection} from '@cloudscape-design/collection-hooks';
import {
  Box,
  Button,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
} from '@cloudscape-design/components';
import * as luxon from 'luxon';
import Link from 'next/link';
import {useCallback, useContext, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {AppContext} from '../../models/app-context';
import RouterButton from '../wrappers/router-button';

export interface SessionsProps {
  toolsOpen: boolean;
}

export default function Sessions(props: SessionsProps) {
  const appContext = useContext(AppContext);
  const [sessions, setSessions] = useState<SessionInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const {items, collectionProps, paginationProps} = useCollection(sessions, {
    filtering: {
      empty: (
        <Box margin={{vertical: 'xs'}} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No sessions</b>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: {pageSize: 18},
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'startTime',
        },
        isDescending: true,
      },
    },
    selection: {},
  });

  const getSessions = useCallback(async () => {
    // if (!appContext) return;
    setIsLoading(true);
    chatSessionRepo.getSessions().then(result => {
      setSessions(result);
      setIsLoading(false);
    });
  }, [appContext]);

  useEffect(() => {
    // if (!appContext) return;
    // if (!props.toolsOpen) return;

    setIsLoading(true);
    getSessions().then(() => {
      setIsLoading(false);
    });
  }, [appContext, getSessions, props.toolsOpen]);

  const deleteSession = async (sessionId: string) => {
    if (!appContext) return;

    setIsLoading(true);
    await chatSessionRepo.deleteSession(sessionId);
    await getSessions();
    setIsLoading(false);
  };

  const deleteUserSessions = async () => {
    if (!appContext) return;
    if (!confirm('Are you sure you want to delete all sessions?')) return;

    setIsLoading(true);
    await chatSessionRepo.deleteAllSessions();
    await getSessions();
    setIsLoading(false);
  };

  return (
    <div style={{padding: '0px 14px'}}>
      <Table
        {...collectionProps}
        variant="embedded"
        items={items}
        pagination={<Pagination {...paginationProps} />}
        loadingText="Loading history"
        loading={isLoading}
        resizableColumns
        header={
          <div style={{paddingTop: '4px'}}>
            <h2>History</h2>
            <div>
              <SpaceBetween direction="horizontal" size="m" alignItems="center">
                <RouterButton
                  iconName="add-plus"
                  href={`/${uuidv4()}`}
                  variant="inline-link"
                >
                  New session
                </RouterButton>
                <Button
                  iconAlt="Refresh list"
                  iconName="refresh"
                  variant="inline-link"
                  onClick={() => getSessions()}
                >
                  Refresh
                </Button>
                <Button
                  iconAlt="Delete all sessions"
                  iconName="delete-marker"
                  variant="inline-link"
                  onClick={() => deleteUserSessions()}
                >
                  Delete all sessions
                </Button>
              </SpaceBetween>
            </div>
          </div>
        }
        columnDefinitions={
          [
            {
              id: 'name',
              header: 'Name',
              sortingField: 'name',
              cell: e => <Link href={`/${e.id}`}>{e.name}</Link>,
              isRowHeader: true,
            },
            {
              id: 'createdOn',
              header: 'Time',
              sortingField: 'createdOn',
              cell: (e: SessionInfo) => e.createdOn.toLocaleString(),
              sortingComparator: (a, b) =>
                luxon.DateTime.fromISO(b.createdOn.toString()).diff(
                  luxon.DateTime.fromISO(a.createdOn.toString())
                ),
            },
            // {
            //   id: "open",
            //   header: "Open",
            //   cell: (item) => (
            //     <RouterButton
            //       variant="inline-link"
            //       href={`/chatbot/${item.id}`}
            //     >
            //       Open
            //     </RouterButton>
            //   ),
            // },
            {
              id: 'delete',
              header: '',
              cell: item => (
                <Button
                  iconAlt="Delete"
                  iconName="delete-marker"
                  variant="inline-link"
                  ariaLabel="Delete this conversation."
                  onClick={() => deleteSession(item.id)}
                ></Button>
              ),
            },
          ] as TableProps.ColumnDefinition<SessionInfo>[]
        }
      />
    </div>
  );
}
