'use client';

import styles from '@/styles/chat.module.scss';
import 'bootstrap/dist/css/bootstrap.css';

import {Mermaid} from '@/components/mermaid';
import {ReactElement} from 'react';
import ReactMarkdown from 'react-markdown';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import {StatusIndicator} from '@cloudscape-design/components';

type HomeRequiredProps = {
  text: string;
};

type HomeOptionalProps = {
  // Some output types won't be rendered until the loading phase is complete.
  isLoading: boolean;
  onRenderComplete: (() => void) | undefined;
};

type HomeProps = HomeRequiredProps & HomeOptionalProps;

const defaultProps: HomeOptionalProps = {
  isLoading: false,
  onRenderComplete: undefined,
};

export default function Home(props: HomeProps) {
  props.isLoading;
  return (
    <ReactMarkdown
      children={props.text}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        ul(props) {
          return <ul {...props} className="list-group" />;
        },
        li(props) {
          return <li {...props} className="list-group-item" />;
        },
        pre(preProps) {
          const {children, ...rest} = preProps;

          if (preProps.children) {
            const children = preProps.children as ReactElement;
            if (children.props) {
              if (children.props.className === 'language-mermaid') {
                // console.log('I found a mermaid!');
                // console.log(children.props.children);
                // console.log(
                //   'Is complete: ',
                //   children.props.children!.toString()
                // );
                if (props.isLoading) {
                  return (
                    <StatusIndicator type="in-progress">
                      Generating diagram...
                    </StatusIndicator>
                  );
                } else {
                  return (
                    <Mermaid
                      chart={children.props.children.toString()}
                      onRenderComplete={props.onRenderComplete}
                    />
                  );
                }
              }
            }
          }
          return (
            <pre {...rest} className={styles.codeMarkdown}>
              {children}
            </pre>
          );
        },
        code({className, children, ref: _ref, ...props}) {
          const match = /language-(\w+)/.exec(className || '');

          return match ? (
            <SyntaxHighlighter
              {...props}
              style={dracula}
              PreTag="div"
              language={match[1]}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    />
  );
}

Home.defaultProps = defaultProps;
