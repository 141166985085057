'use client';

import {
  SideNavigation,
  SideNavigationProps,
} from '@cloudscape-design/components';
import {useContext, useEffect, useState} from 'react';
import {useNavigationPanelState} from '../hooks/use-navigation-panel-state';
import useOnFollow from '../hooks/use-on-follow';
import {AppContext} from '../models/app-context';
import {v4 as uuidv4} from 'uuid';

export default function NavigationPanel() {
  const appContext = useContext(AppContext);
  const onFollow = useOnFollow();
  const [navigationPanelState, setNavigationPanelState] =
    useNavigationPanelState();
  const [name, setName] = useState('');
  const [items, setItems] = useState<SideNavigationProps.Item[]>([]);

  useEffect(() => {
    setItems([
      {
        type: 'section',
        text: name,
        // className: "gray-text",
        items: [
          {type: 'link', text: 'Home', href: '/'},
          {type: 'link', text: 'Logout', href: '/logout'},
        ],
      },
      {
        type: 'section',
        // text: <h1 className="red">Chatbot</h1>,
        text: 'Chatbot',
        items: [{type: 'link', text: 'New Conversation', href: `/${uuidv4()}`}],
      },
      {type: 'divider'},
      {
        type: 'section',
        text: 'Docs',
        items: [
          {
            type: 'link',
            text: 'Documentation',
            href: '/documentation',
            external: false,
          },
          {
            type: 'link',
            text: 'Confluence SDD',
            href: 'https://cefit.atlassian.net/wiki/spaces/AE/pages/4076699691/AI+as+a+Platform+AIaaP+UI+BFF+Solution+Design',
            external: true,
          },
          {
            type: 'link',
            text: 'Jira',
            href: 'https://cefit.atlassian.net/browse/CCP-3',
            external: true,
          },
          {
            type: 'link',
            text: 'GitLab',
            href: 'https://gitlab.com/cef-cloud/common-components/city-chat',
            external: true,
          },
        ],
      },
    ]);
  }, [name]);

  useEffect(() => {
    const userDetails = appContext?.userSession.userDetails;
    // console.log('userDetails', userDetails)
    if (userDetails) {
      setName(userDetails.name);
      // console.log('name', userDetails.name)
    }
  }, [appContext, appContext?.userSession.userDetails.name]);

  const onChange = ({detail}: {detail: SideNavigationProps.ChangeDetail}) => {
    const sectionIndex = items.indexOf(detail.item);
    setNavigationPanelState({
      collapsedSections: {
        ...navigationPanelState.collapsedSections,
        [sectionIndex]: !detail.expanded,
      },
    });
  };

  return (
    <SideNavigation
      onFollow={onFollow}
      onChange={onChange}
      header={{
        href: '/',
        text: 'City Chat', // <span><span className='logo'></span> <span className="city-chat-label">City Chat</span></span>,
        logo: {
          src: '/AI.svg',
        },
      }}
      items={items.map((value, idx) => {
        if (value.type === 'section') {
          const collapsed =
            navigationPanelState.collapsedSections?.[idx] === true;
          value.defaultExpanded = !collapsed;
        }

        return value;
      })}
    />
  );
}
